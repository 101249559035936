@import "frappe/public/css/fonts/fontawesome/font-awesome.min.css";
.chat-app {
  position: fixed;
  bottom: 24px;
  right: 0px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  min-width: 350px;
  z-index: 1030;
  padding: 0 1rem;
}
.chat-app ::-webkit-scrollbar {
  width: 2px;
}
.chat-app ::-webkit-scrollbar-track {
  background: var(--card-bg);
}
.chat-app ::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb-color);
  border-radius: 10px;
  max-height: 30px;
}
.chat-app ::-webkit-scrollbar-thumb:hover {
  background: var(--gray-500);
}
.chat-app .chat_left_section {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
}
.chat-app .chat_left_section .chat-window {
  height: 582px;
  width: 350px;
  position: relative;
  box-shadow: 0px 2px 6px rgba(17, 43, 66, 0.08), 0px 1px 4px rgba(17, 43, 66, 0.1);
  background: var(--card-bg);
  border-radius: 6px;
  margin-bottom: 1rem;
  margin-right: 5px;
  border: 1px solid var(--dark-border-color);
}
.chat-app .chat_right_section {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  align-self: flex-end;
}
.chat-app .chat_right_section .chat-element {
  height: 582px;
  width: 350px;
  position: relative;
  box-shadow: 0px 2px 6px rgba(17, 43, 66, 0.08), 0px 1px 4px rgba(17, 43, 66, 0.1);
  background: var(--card-bg);
  border-radius: 6px;
  margin-bottom: 1rem;
  border: 1px solid var(--dark-border-color);
}
.chat-app .chat_right_section .chat-element .chat-container {
  padding: 0;
  margin: 0;
}
.chat-app .chat_right_section .chat-element .chat-cross-button {
  display: none;
  color: var(--gray-700);
  cursor: pointer;
  position: absolute;
  top: 12px;
  right: 8px;
}
.chat-app .chat_right_section .chat-bubble {
  cursor: pointer;
  background: #181f2f;
  color: var(--white);
  border-radius: 100px;
  font-weight: bold;
  display: flex;
  align-items: center;
  width: initial;
}
.chat-app .chat_right_section .chat-bubble svg {
  fill: var(--white);
}
.chat-app .chat_right_section .chat-bubble:hover {
  box-shadow: var(--shadow-lg);
}
.chat-app .chat_right_section .chat-bubble .chat-message-icon {
  margin-right: var(--margin-sm);
  display: flex;
  align-items: center;
  justify-content: center;
}
.chat-app .chat_right_section .chat-bubble-closed {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 22px;
}
.chat-app .chat_right_section .chat-bubble-closed .cross-icon {
  width: 100%;
}

.ask-to-join-container strong {
  white-space: break-spaces;
}

.infobutton {
  height: 50px;
  display: flex;
  color: var(--text-color);
  flex-grow: 1;
  flex-direction: column;
  font-size: 0.875rem;
  align-items: center;
  text-transform: capitalize;
  justify-content: center;
  outline: none;
  padding: 0;
  cursor: pointer;
  background: none;
  border: 0;
  padding-top: 4px;
  margin: 0;
}

.infobutton.active {
  border-bottom: 4px solid #adadad;
}

.gname[readonly] {
  border: none;
  outline: none;
}

.gname {
  text-align: center;
}

.tag-blot-hidden {
  display: none;
}

.contributors_count,
.contributors_close,
.contributors_count_icon {
  cursor: pointer;
}

.chat-media {
  background-color: #f0f2f5;
}
.chat-media .mediatabcontainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.chat-media .mediatabcontainer video,
.chat-media .mediatabcontainer a {
  display: flex;
  width: 136px !important;
  height: 100px;
  margin: 8px;
}
.chat-media .mediatabcontainer video img,
.chat-media .mediatabcontainer a img {
  -o-object-fit: cover !important;
     object-fit: cover !important;
  width: 100%;
  height: 100%;
}
.chat-media .linkstabcontainer .linksrow {
  margin: 8px;
  display: block;
  padding: 8px;
  background: #f4f5f6;
  width: 100%;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-top-right-radius: 8px;
  margin: 8px;
  word-wrap: break-word;
}
.chat-media .docstabcontainer .document-container {
  padding: 8px;
  background: #f4f5f6;
  width: 100%;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-top-right-radius: 8px;
  margin: 8px;
}
.chat-media .docstabcontainer .document-container a {
  text-decoration: none !important;
}

.chat_bottom {
  bottom: 0;
  position: fixed;
  display: flex;
  flex-direction: row;
  background: white;
  overflow-y: scroll;
  max-width: 98%;
}
.chat_bottom div {
  padding: 6px;
  margin-right: 1px;
}

[data-theme=dark] .chat_bottom {
  background: #1c2126;
}
[data-theme=dark] .minimized-chat {
  border-color: #434a52;
}
[data-theme=dark] .chat-info {
  background: #161a1f;
}
[data-theme=dark] .chat-info .gname {
  background: #1c2126;
  border-color: black;
}
[data-theme=dark] .chat-info input:focus {
  outline: none;
}
[data-theme=dark] .infoheader,
[data-theme=dark] .chat-info-section {
  background: #1c2126;
}
[data-theme=dark] .chat-space .tag-section {
  background-color: #1c2126 !important;
}
[data-theme=dark] .chat-space .tag-section .tags-container {
  background-color: #1c2126 !important;
  box-shadow: 0 0 5px 2px #000;
}
[data-theme=dark] .chat-space .tag-section .tags-container .tag-blot {
  border-bottom: 1px solid #7a838c !important;
}
[data-theme=dark] .chat-space .tag-section .tags-container .tag-blot:last-child {
  border-bottom: none !important;
}
[data-theme=dark] .chat-media {
  background: #1c2126;
}
[data-theme=dark] .chat-media .infobutton {
  color: #ffffff;
}
[data-theme=dark] .chat-media .infobutton:active {
  border-bottom: 4px solid #ffffff;
}
[data-theme=dark] .chat-media .linkstabcontainer .linksrow {
  background: #242a30;
}
[data-theme=dark] .chat-media .docstabcontainer .document-container {
  background: #242a30;
}
[data-theme=dark] .chat-space .chat-space-container .mention {
  color: #f9f9f9;
  background-color: var(--user-mention-bg-color);
}
[data-theme=dark] .chat-space .chat-space-container .sender-message .message-bubble {
  background: #394149;
  color: #f9f9f9;
}
[data-theme=dark] .chat-space .chat-space-container .info-message {
  color: black;
}
[data-theme=dark] .chat-topic-space .chat-topic-space-container .mention {
  color: #f9f9f9;
  background-color: var(--user-mention-bg-color);
}
[data-theme=dark] .chat-topic-space .chat-topic-space-container .sender-message .message-bubble {
  background: #394149;
  color: #f9f9f9;
}
[data-theme=dark] .chat-topic-space .chat-topic-space-container .info-message {
  color: black;
}
[data-theme=dark] .chat-list .chat-rooms-group-container .chat-room .chat-latest,
[data-theme=dark] .chat-list .chat-rooms-group-container .chat-room-group .chat-latest,
[data-theme=dark] .chat-list .chat-rooms-group-container .chat-contact .chat-latest,
[data-theme=dark] .chat-list .chat-rooms-group-container .new-group .chat-latest,
[data-theme=dark] .chat-list .chat-rooms-container .chat-room .chat-latest,
[data-theme=dark] .chat-list .chat-rooms-container .chat-room-group .chat-latest,
[data-theme=dark] .chat-list .chat-rooms-container .chat-contact .chat-latest,
[data-theme=dark] .chat-list .chat-rooms-container .new-group .chat-latest,
[data-theme=dark] .chat-list .chat-contacts-container .chat-room .chat-latest,
[data-theme=dark] .chat-list .chat-contacts-container .chat-room-group .chat-latest,
[data-theme=dark] .chat-list .chat-contacts-container .chat-contact .chat-latest,
[data-theme=dark] .chat-list .chat-contacts-container .new-group .chat-latest,
[data-theme=dark] .chat-contact-list .chat-rooms-group-container .chat-room .chat-latest,
[data-theme=dark] .chat-contact-list .chat-rooms-group-container .chat-room-group .chat-latest,
[data-theme=dark] .chat-contact-list .chat-rooms-group-container .chat-contact .chat-latest,
[data-theme=dark] .chat-contact-list .chat-rooms-group-container .new-group .chat-latest,
[data-theme=dark] .chat-contact-list .chat-rooms-container .chat-room .chat-latest,
[data-theme=dark] .chat-contact-list .chat-rooms-container .chat-room-group .chat-latest,
[data-theme=dark] .chat-contact-list .chat-rooms-container .chat-contact .chat-latest,
[data-theme=dark] .chat-contact-list .chat-rooms-container .new-group .chat-latest,
[data-theme=dark] .chat-contact-list .chat-contacts-container .chat-room .chat-latest,
[data-theme=dark] .chat-contact-list .chat-contacts-container .chat-room-group .chat-latest,
[data-theme=dark] .chat-contact-list .chat-contacts-container .chat-contact .chat-latest,
[data-theme=dark] .chat-contact-list .chat-contacts-container .new-group .chat-latest,
[data-theme=dark] .conversation-list .chat-rooms-group-container .chat-room .chat-latest,
[data-theme=dark] .conversation-list .chat-rooms-group-container .chat-room-group .chat-latest,
[data-theme=dark] .conversation-list .chat-rooms-group-container .chat-contact .chat-latest,
[data-theme=dark] .conversation-list .chat-rooms-group-container .new-group .chat-latest,
[data-theme=dark] .conversation-list .chat-rooms-container .chat-room .chat-latest,
[data-theme=dark] .conversation-list .chat-rooms-container .chat-room-group .chat-latest,
[data-theme=dark] .conversation-list .chat-rooms-container .chat-contact .chat-latest,
[data-theme=dark] .conversation-list .chat-rooms-container .new-group .chat-latest,
[data-theme=dark] .conversation-list .chat-contacts-container .chat-room .chat-latest,
[data-theme=dark] .conversation-list .chat-contacts-container .chat-room-group .chat-latest,
[data-theme=dark] .conversation-list .chat-contacts-container .chat-contact .chat-latest,
[data-theme=dark] .conversation-list .chat-contacts-container .new-group .chat-latest {
  background: var(--red-avatar-color);
  color: black;
}
[data-theme=dark] .container1 {
  border-top: 1.5px solid #fff;
}
[data-theme=dark] .container1 .chatlist {
  background-color: var(--gray-900);
}
[data-theme=dark] .container1 .search_chat {
  background-color: var(--gray-900);
}
[data-theme=dark] .container1 .header {
  background-color: var(--gray-900);
}
[data-theme=dark] .container1 .search_chat .search input {
  background-color: var(--gray-700);
}
[data-theme=dark] .container1 .nav-icons li {
  color: white;
}
[data-theme=dark] .container1 .chatlist .block .details .listHead h4 {
  color: #c9d0d6;
}
[data-theme=dark] .container1 .chatlist .block.active {
  background: var(--bg-light-gray);
}
[data-theme=dark] .container1 .chatlist .block:hover {
  background: var(--bg-light-gray);
}
[data-theme=dark] .container1 .chatlist .block.unread .details .listHead .time {
  color: #7A838c;
}
[data-theme=dark] .container1 .infosectionchatportal {
  background-color: var(--gray-900);
}
[data-theme=dark] .container1 .message p {
  background-color: var(--control-bg);
  color: var(--text-color);
}
[data-theme=dark] .container1 .message-form-container {
  background-color: var(--gray-700);
}
[data-theme=dark] .container1 .timemessagechat {
  color: #7A838c;
}
[data-theme=dark] .container1 .contactdetailbox {
  background-color: var(--gray-900);
  color: var(--text-color);
  border-bottom: 3px solid black;
}
[data-theme=dark] .container1 #sendButton {
  background-color: var(--gray-700);
  color: white;
}
[data-theme=dark] .container1 .rightSide {
  background-color: var(--gray-900);
}
[data-theme=dark] .container1 .contactsapi .chatlist {
  background-color: var(--gray-900);
}
[data-theme=dark] .container1 .leftSide {
  background-color: var(--gray-900);
}
[data-theme=dark] .container1 #filterunreadmessages {
  background-color: var(--gray-900);
  color: var(--text-color);
}
[data-theme=dark] .container1 .filter_resaults {
  background-color: var(--gray-900);
}
[data-theme=dark] .container1 .timemessagechatheader {
  color: skyblue;
}
[data-theme=dark] .container1 .buttonfor-media-Links-Docs {
  color: var(--text-color);
  background-color: var(--gray-900);
}
[data-theme=dark] .container1 .media-links-docs {
  background-color: var(--gray-900);
}
[data-theme=dark] .container1 .infosectionandmedia {
  background-color: var(--gray-700);
}
[data-theme=dark] .container1 .checkedelements {
  background-color: var(--gray-500);
}
[data-theme=dark] .container1 #record-button {
  background-color: var(--gray-700);
  color: white;
}
[data-theme=dark] .container1 .document-container {
  background-color: #394149;
}
[data-theme=dark] .container1 .box_media_document_link {
  /* background-color: var(--control-bg); */
  color: #111;
  background-color: var(--gray-700);
}
[data-theme=dark] .container1 .box_media_document_link p {
  /* background-color: var(--control-bg); */
  background-color: var(--gray-700);
}
[data-theme=dark] .container1 #filterunreadmessages {
  background-color: var(--gray-900);
}

.show-contributors {
  position: absolute;
  right: 55px;
  display: flex;
  flex-direction: column;
}

.chat-info {
  top: 0;
  position: absolute;
  background: white;
  height: 100%;
  width: 100%;
  overflow: auto;
}
.chat-info .roomname input[placeholder] {
  text-overflow: ellipsis;
}
.chat-info .avatar-info .avatar {
  width: 100px !important;
  height: 100px !important;
}
.chat-info .exit-group {
  cursor: pointer;
}

.minimized-chat {
  border-radius: 8px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-color: #f4f5f6;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0px 18px 22px rgba(25, 39, 52, 0.1), 0px 1px 10px rgba(0, 0, 0, 0.06), 0px 0.5px 5px rgba(25, 39, 52, 0.04);
}

.infoheader,
.chat-info {
  background-color: #f0f2f5;
}
.infoheader .exitMe,
.infoheader .back-to-chat-info,
.chat-info .exitMe,
.chat-info .back-to-chat-info {
  cursor: pointer;
}

.chat-info .add_members {
  cursor: pointer;
}
.chat-info .list_members {
  margin-left: 8px;
  max-height: 120px;
  overflow: auto;
  width: 100%;
}
.chat-info .add_this_member {
  padding: 8px 0 0 0;
  cursor: pointer;
  border-bottom: 1px solid gray;
}
.chat-info .add_this_member:last-child {
  border-bottom: none;
}
.chat-info .close_members_lis {
  visibility: hidden;
  cursor: pointer;
}
.chat-info .filter-members {
  display: none;
  margin: 8px;
  width: 100%;
}

.infoheader {
  position: sticky;
  top: 0;
  z-index: 2;
}

.chat-media {
  z-index: 3;
}

.chat-media-tabs {
  position: sticky;
  top: 66px;
}

.medeiaheader {
  position: sticky;
  top: 0;
  z-index: 3;
}

.chat-info-section {
  background-color: white;
  margin-top: 4px;
  margin-bottom: 4px;
}

.chat-list .chat-list-header,
.chat-contact-list .chat-list-header,
.conversation-list .chat-list-header {
  padding: 12px 12px 0 12px;
  justify-content: space-between;
  display: flex;
}
.chat-list .chat-list-header h3,
.chat-contact-list .chat-list-header h3,
.conversation-list .chat-list-header h3 {
  font-size: 1.25rem;
  font-weight: 700 !important;
  margin-top: 0px;
  margin-bottom: 15px;
}
.chat-list .chat-list-header .add-participants,
.chat-list .chat-list-header .select-contacts,
.chat-contact-list .chat-list-header .add-participants,
.chat-contact-list .chat-list-header .select-contacts,
.conversation-list .chat-list-header .add-participants,
.conversation-list .chat-list-header .select-contacts {
  font-size: small;
  font-weight: normal;
}
.chat-list .chat-list-header .back-to-chat-list,
.chat-contact-list .chat-list-header .back-to-chat-list,
.conversation-list .chat-list-header .back-to-chat-list {
  cursor: pointer;
}
.chat-list .chat-list-header .chat-list-icons,
.chat-contact-list .chat-list-header .chat-list-icons,
.conversation-list .chat-list-header .chat-list-icons {
  display: flex;
}
.chat-list .chat-list-header .chat-list-icons .close-chat-list,
.chat-contact-list .chat-list-header .chat-list-icons .close-chat-list,
.conversation-list .chat-list-header .chat-list-icons .close-chat-list {
  cursor: pointer;
  margin-right: 0px;
  margin-top: 1px;
}
.chat-list .chat-list-header .chat-list-icons .add-room,
.chat-list .chat-list-header .chat-list-icons .show-all-contacts,
.chat-list .chat-list-header .chat-list-icons .show-rooms,
.chat-list .chat-list-header .chat-list-icons .new-chat,
.chat-list .chat-list-header .chat-list-icons .support-icon,
.chat-list .chat-list-header .chat-list-icons .thumbtack-icon,
.chat-list .chat-list-header .chat-list-icons .rotate-thumbtack-icon,
.chat-list .chat-list-header .chat-list-icons .save-icon,
.chat-contact-list .chat-list-header .chat-list-icons .add-room,
.chat-contact-list .chat-list-header .chat-list-icons .show-all-contacts,
.chat-contact-list .chat-list-header .chat-list-icons .show-rooms,
.chat-contact-list .chat-list-header .chat-list-icons .new-chat,
.chat-contact-list .chat-list-header .chat-list-icons .support-icon,
.chat-contact-list .chat-list-header .chat-list-icons .thumbtack-icon,
.chat-contact-list .chat-list-header .chat-list-icons .rotate-thumbtack-icon,
.chat-contact-list .chat-list-header .chat-list-icons .save-icon,
.conversation-list .chat-list-header .chat-list-icons .add-room,
.conversation-list .chat-list-header .chat-list-icons .show-all-contacts,
.conversation-list .chat-list-header .chat-list-icons .show-rooms,
.conversation-list .chat-list-header .chat-list-icons .new-chat,
.conversation-list .chat-list-header .chat-list-icons .support-icon,
.conversation-list .chat-list-header .chat-list-icons .thumbtack-icon,
.conversation-list .chat-list-header .chat-list-icons .rotate-thumbtack-icon,
.conversation-list .chat-list-header .chat-list-icons .save-icon {
  cursor: pointer;
  margin-right: 11px;
  margin-top: 1px;
}
.chat-list .chat-search,
.chat-contact-list .chat-search,
.conversation-list .chat-search {
  border-radius: 6px;
  font-size: 0.875rem;
  margin: 0 12px;
}
.chat-list .chat-search .search-icon,
.chat-contact-list .chat-search .search-icon,
.conversation-list .chat-search .search-icon {
  display: flex;
  align-items: center;
  position: absolute;
  padding-right: 10px;
  height: 100%;
  margin-left: 12px;
  z-index: 3;
}
.chat-list .chat-search .chat-search-box,
.chat-contact-list .chat-search .chat-search-box,
.conversation-list .chat-search .chat-search-box {
  width: 100%;
  border-radius: 6px;
  padding-left: 36px;
}
.chat-list .chat-search .chat-search-box:focus,
.chat-contact-list .chat-search .chat-search-box:focus,
.conversation-list .chat-search .chat-search-box:focus {
  box-shadow: none;
}
.chat-list .chat-rooms-group-container,
.chat-list .chat-rooms-container,
.chat-list .chat-contacts-container,
.chat-contact-list .chat-rooms-group-container,
.chat-contact-list .chat-rooms-container,
.chat-contact-list .chat-contacts-container,
.conversation-list .chat-rooms-group-container,
.conversation-list .chat-rooms-container,
.conversation-list .chat-contacts-container {
  height: 490.5px;
  overflow-y: auto;
  padding: 12px;
}
.chat-list .chat-rooms-group-container .empty-chat-list-container,
.chat-list .chat-rooms-container .empty-chat-list-container,
.chat-list .chat-contacts-container .empty-chat-list-container,
.chat-contact-list .chat-rooms-group-container .empty-chat-list-container,
.chat-contact-list .chat-rooms-container .empty-chat-list-container,
.chat-contact-list .chat-contacts-container .empty-chat-list-container,
.conversation-list .chat-rooms-group-container .empty-chat-list-container,
.conversation-list .chat-rooms-container .empty-chat-list-container,
.conversation-list .chat-contacts-container .empty-chat-list-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.chat-list .chat-rooms-group-container .chat-room,
.chat-list .chat-rooms-group-container .chat-room-group,
.chat-list .chat-rooms-group-container .chat-contact,
.chat-list .chat-rooms-group-container .new-group,
.chat-list .chat-rooms-container .chat-room,
.chat-list .chat-rooms-container .chat-room-group,
.chat-list .chat-rooms-container .chat-contact,
.chat-list .chat-rooms-container .new-group,
.chat-list .chat-contacts-container .chat-room,
.chat-list .chat-contacts-container .chat-room-group,
.chat-list .chat-contacts-container .chat-contact,
.chat-list .chat-contacts-container .new-group,
.chat-contact-list .chat-rooms-group-container .chat-room,
.chat-contact-list .chat-rooms-group-container .chat-room-group,
.chat-contact-list .chat-rooms-group-container .chat-contact,
.chat-contact-list .chat-rooms-group-container .new-group,
.chat-contact-list .chat-rooms-container .chat-room,
.chat-contact-list .chat-rooms-container .chat-room-group,
.chat-contact-list .chat-rooms-container .chat-contact,
.chat-contact-list .chat-rooms-container .new-group,
.chat-contact-list .chat-contacts-container .chat-room,
.chat-contact-list .chat-contacts-container .chat-room-group,
.chat-contact-list .chat-contacts-container .chat-contact,
.chat-contact-list .chat-contacts-container .new-group,
.conversation-list .chat-rooms-group-container .chat-room,
.conversation-list .chat-rooms-group-container .chat-room-group,
.conversation-list .chat-rooms-group-container .chat-contact,
.conversation-list .chat-rooms-group-container .new-group,
.conversation-list .chat-rooms-container .chat-room,
.conversation-list .chat-rooms-container .chat-room-group,
.conversation-list .chat-rooms-container .chat-contact,
.conversation-list .chat-rooms-container .new-group,
.conversation-list .chat-contacts-container .chat-room,
.conversation-list .chat-contacts-container .chat-room-group,
.conversation-list .chat-contacts-container .chat-contact,
.conversation-list .chat-contacts-container .new-group {
  display: flex;
  font-size: 0.875rem;
  cursor: pointer;
  position: relative;
  padding: 10px 0;
  border-radius: 8px;
}
.chat-list .chat-rooms-group-container .chat-room:hover,
.chat-list .chat-rooms-group-container .chat-room-group:hover,
.chat-list .chat-rooms-group-container .chat-contact:hover,
.chat-list .chat-rooms-group-container .new-group:hover,
.chat-list .chat-rooms-container .chat-room:hover,
.chat-list .chat-rooms-container .chat-room-group:hover,
.chat-list .chat-rooms-container .chat-contact:hover,
.chat-list .chat-rooms-container .new-group:hover,
.chat-list .chat-contacts-container .chat-room:hover,
.chat-list .chat-contacts-container .chat-room-group:hover,
.chat-list .chat-contacts-container .chat-contact:hover,
.chat-list .chat-contacts-container .new-group:hover,
.chat-contact-list .chat-rooms-group-container .chat-room:hover,
.chat-contact-list .chat-rooms-group-container .chat-room-group:hover,
.chat-contact-list .chat-rooms-group-container .chat-contact:hover,
.chat-contact-list .chat-rooms-group-container .new-group:hover,
.chat-contact-list .chat-rooms-container .chat-room:hover,
.chat-contact-list .chat-rooms-container .chat-room-group:hover,
.chat-contact-list .chat-rooms-container .chat-contact:hover,
.chat-contact-list .chat-rooms-container .new-group:hover,
.chat-contact-list .chat-contacts-container .chat-room:hover,
.chat-contact-list .chat-contacts-container .chat-room-group:hover,
.chat-contact-list .chat-contacts-container .chat-contact:hover,
.chat-contact-list .chat-contacts-container .new-group:hover,
.conversation-list .chat-rooms-group-container .chat-room:hover,
.conversation-list .chat-rooms-group-container .chat-room-group:hover,
.conversation-list .chat-rooms-group-container .chat-contact:hover,
.conversation-list .chat-rooms-group-container .new-group:hover,
.conversation-list .chat-rooms-container .chat-room:hover,
.conversation-list .chat-rooms-container .chat-room-group:hover,
.conversation-list .chat-rooms-container .chat-contact:hover,
.conversation-list .chat-rooms-container .new-group:hover,
.conversation-list .chat-contacts-container .chat-room:hover,
.conversation-list .chat-contacts-container .chat-room-group:hover,
.conversation-list .chat-contacts-container .chat-contact:hover,
.conversation-list .chat-contacts-container .new-group:hover {
  background: var(--bg-light-gray);
  /* Neat trick to add hover spacing without affecting layout */
  border-left: 5px solid var(--bg-light-gray);
  border-right: 5px solid var(--bg-light-gray);
  margin: 0 -5px;
}
.chat-list .chat-rooms-group-container .chat-room .avatar,
.chat-list .chat-rooms-group-container .chat-room-group .avatar,
.chat-list .chat-rooms-group-container .chat-contact .avatar,
.chat-list .chat-rooms-group-container .new-group .avatar,
.chat-list .chat-rooms-container .chat-room .avatar,
.chat-list .chat-rooms-container .chat-room-group .avatar,
.chat-list .chat-rooms-container .chat-contact .avatar,
.chat-list .chat-rooms-container .new-group .avatar,
.chat-list .chat-contacts-container .chat-room .avatar,
.chat-list .chat-contacts-container .chat-room-group .avatar,
.chat-list .chat-contacts-container .chat-contact .avatar,
.chat-list .chat-contacts-container .new-group .avatar,
.chat-contact-list .chat-rooms-group-container .chat-room .avatar,
.chat-contact-list .chat-rooms-group-container .chat-room-group .avatar,
.chat-contact-list .chat-rooms-group-container .chat-contact .avatar,
.chat-contact-list .chat-rooms-group-container .new-group .avatar,
.chat-contact-list .chat-rooms-container .chat-room .avatar,
.chat-contact-list .chat-rooms-container .chat-room-group .avatar,
.chat-contact-list .chat-rooms-container .chat-contact .avatar,
.chat-contact-list .chat-rooms-container .new-group .avatar,
.chat-contact-list .chat-contacts-container .chat-room .avatar,
.chat-contact-list .chat-contacts-container .chat-room-group .avatar,
.chat-contact-list .chat-contacts-container .chat-contact .avatar,
.chat-contact-list .chat-contacts-container .new-group .avatar,
.conversation-list .chat-rooms-group-container .chat-room .avatar,
.conversation-list .chat-rooms-group-container .chat-room-group .avatar,
.conversation-list .chat-rooms-group-container .chat-contact .avatar,
.conversation-list .chat-rooms-group-container .new-group .avatar,
.conversation-list .chat-rooms-container .chat-room .avatar,
.conversation-list .chat-rooms-container .chat-room-group .avatar,
.conversation-list .chat-rooms-container .chat-contact .avatar,
.conversation-list .chat-rooms-container .new-group .avatar,
.conversation-list .chat-contacts-container .chat-room .avatar,
.conversation-list .chat-contacts-container .chat-room-group .avatar,
.conversation-list .chat-contacts-container .chat-contact .avatar,
.conversation-list .chat-contacts-container .new-group .avatar {
  flex-shrink: 0;
}
.chat-list .chat-rooms-group-container .chat-room .chat-profile-info,
.chat-list .chat-rooms-group-container .chat-room-group .chat-profile-info,
.chat-list .chat-rooms-group-container .chat-contact .chat-profile-info,
.chat-list .chat-rooms-group-container .new-group .chat-profile-info,
.chat-list .chat-rooms-container .chat-room .chat-profile-info,
.chat-list .chat-rooms-container .chat-room-group .chat-profile-info,
.chat-list .chat-rooms-container .chat-contact .chat-profile-info,
.chat-list .chat-rooms-container .new-group .chat-profile-info,
.chat-list .chat-contacts-container .chat-room .chat-profile-info,
.chat-list .chat-contacts-container .chat-room-group .chat-profile-info,
.chat-list .chat-contacts-container .chat-contact .chat-profile-info,
.chat-list .chat-contacts-container .new-group .chat-profile-info,
.chat-contact-list .chat-rooms-group-container .chat-room .chat-profile-info,
.chat-contact-list .chat-rooms-group-container .chat-room-group .chat-profile-info,
.chat-contact-list .chat-rooms-group-container .chat-contact .chat-profile-info,
.chat-contact-list .chat-rooms-group-container .new-group .chat-profile-info,
.chat-contact-list .chat-rooms-container .chat-room .chat-profile-info,
.chat-contact-list .chat-rooms-container .chat-room-group .chat-profile-info,
.chat-contact-list .chat-rooms-container .chat-contact .chat-profile-info,
.chat-contact-list .chat-rooms-container .new-group .chat-profile-info,
.chat-contact-list .chat-contacts-container .chat-room .chat-profile-info,
.chat-contact-list .chat-contacts-container .chat-room-group .chat-profile-info,
.chat-contact-list .chat-contacts-container .chat-contact .chat-profile-info,
.chat-contact-list .chat-contacts-container .new-group .chat-profile-info,
.conversation-list .chat-rooms-group-container .chat-room .chat-profile-info,
.conversation-list .chat-rooms-group-container .chat-room-group .chat-profile-info,
.conversation-list .chat-rooms-group-container .chat-contact .chat-profile-info,
.conversation-list .chat-rooms-group-container .new-group .chat-profile-info,
.conversation-list .chat-rooms-container .chat-room .chat-profile-info,
.conversation-list .chat-rooms-container .chat-room-group .chat-profile-info,
.conversation-list .chat-rooms-container .chat-contact .chat-profile-info,
.conversation-list .chat-rooms-container .new-group .chat-profile-info,
.conversation-list .chat-contacts-container .chat-room .chat-profile-info,
.conversation-list .chat-contacts-container .chat-room-group .chat-profile-info,
.conversation-list .chat-contacts-container .chat-contact .chat-profile-info,
.conversation-list .chat-contacts-container .new-group .chat-profile-info {
  margin-left: var(--margin-md);
  margin-right: auto;
}
.chat-list .chat-rooms-group-container .chat-room .chat-profile-info .chat-name,
.chat-list .chat-rooms-group-container .chat-room-group .chat-profile-info .chat-name,
.chat-list .chat-rooms-group-container .chat-contact .chat-profile-info .chat-name,
.chat-list .chat-rooms-group-container .new-group .chat-profile-info .chat-name,
.chat-list .chat-rooms-container .chat-room .chat-profile-info .chat-name,
.chat-list .chat-rooms-container .chat-room-group .chat-profile-info .chat-name,
.chat-list .chat-rooms-container .chat-contact .chat-profile-info .chat-name,
.chat-list .chat-rooms-container .new-group .chat-profile-info .chat-name,
.chat-list .chat-contacts-container .chat-room .chat-profile-info .chat-name,
.chat-list .chat-contacts-container .chat-room-group .chat-profile-info .chat-name,
.chat-list .chat-contacts-container .chat-contact .chat-profile-info .chat-name,
.chat-list .chat-contacts-container .new-group .chat-profile-info .chat-name,
.chat-contact-list .chat-rooms-group-container .chat-room .chat-profile-info .chat-name,
.chat-contact-list .chat-rooms-group-container .chat-room-group .chat-profile-info .chat-name,
.chat-contact-list .chat-rooms-group-container .chat-contact .chat-profile-info .chat-name,
.chat-contact-list .chat-rooms-group-container .new-group .chat-profile-info .chat-name,
.chat-contact-list .chat-rooms-container .chat-room .chat-profile-info .chat-name,
.chat-contact-list .chat-rooms-container .chat-room-group .chat-profile-info .chat-name,
.chat-contact-list .chat-rooms-container .chat-contact .chat-profile-info .chat-name,
.chat-contact-list .chat-rooms-container .new-group .chat-profile-info .chat-name,
.chat-contact-list .chat-contacts-container .chat-room .chat-profile-info .chat-name,
.chat-contact-list .chat-contacts-container .chat-room-group .chat-profile-info .chat-name,
.chat-contact-list .chat-contacts-container .chat-contact .chat-profile-info .chat-name,
.chat-contact-list .chat-contacts-container .new-group .chat-profile-info .chat-name,
.conversation-list .chat-rooms-group-container .chat-room .chat-profile-info .chat-name,
.conversation-list .chat-rooms-group-container .chat-room-group .chat-profile-info .chat-name,
.conversation-list .chat-rooms-group-container .chat-contact .chat-profile-info .chat-name,
.conversation-list .chat-rooms-group-container .new-group .chat-profile-info .chat-name,
.conversation-list .chat-rooms-container .chat-room .chat-profile-info .chat-name,
.conversation-list .chat-rooms-container .chat-room-group .chat-profile-info .chat-name,
.conversation-list .chat-rooms-container .chat-contact .chat-profile-info .chat-name,
.conversation-list .chat-rooms-container .new-group .chat-profile-info .chat-name,
.conversation-list .chat-contacts-container .chat-room .chat-profile-info .chat-name,
.conversation-list .chat-contacts-container .chat-room-group .chat-profile-info .chat-name,
.conversation-list .chat-contacts-container .chat-contact .chat-profile-info .chat-name,
.conversation-list .chat-contacts-container .new-group .chat-profile-info .chat-name {
  font-weight: bold;
  display: flex;
  align-items: center;
}
.chat-list .chat-rooms-group-container .chat-room .chat-profile-info .conversation_count,
.chat-list .chat-rooms-group-container .chat-room-group .chat-profile-info .conversation_count,
.chat-list .chat-rooms-group-container .chat-contact .chat-profile-info .conversation_count,
.chat-list .chat-rooms-group-container .new-group .chat-profile-info .conversation_count,
.chat-list .chat-rooms-container .chat-room .chat-profile-info .conversation_count,
.chat-list .chat-rooms-container .chat-room-group .chat-profile-info .conversation_count,
.chat-list .chat-rooms-container .chat-contact .chat-profile-info .conversation_count,
.chat-list .chat-rooms-container .new-group .chat-profile-info .conversation_count,
.chat-list .chat-contacts-container .chat-room .chat-profile-info .conversation_count,
.chat-list .chat-contacts-container .chat-room-group .chat-profile-info .conversation_count,
.chat-list .chat-contacts-container .chat-contact .chat-profile-info .conversation_count,
.chat-list .chat-contacts-container .new-group .chat-profile-info .conversation_count,
.chat-contact-list .chat-rooms-group-container .chat-room .chat-profile-info .conversation_count,
.chat-contact-list .chat-rooms-group-container .chat-room-group .chat-profile-info .conversation_count,
.chat-contact-list .chat-rooms-group-container .chat-contact .chat-profile-info .conversation_count,
.chat-contact-list .chat-rooms-group-container .new-group .chat-profile-info .conversation_count,
.chat-contact-list .chat-rooms-container .chat-room .chat-profile-info .conversation_count,
.chat-contact-list .chat-rooms-container .chat-room-group .chat-profile-info .conversation_count,
.chat-contact-list .chat-rooms-container .chat-contact .chat-profile-info .conversation_count,
.chat-contact-list .chat-rooms-container .new-group .chat-profile-info .conversation_count,
.chat-contact-list .chat-contacts-container .chat-room .chat-profile-info .conversation_count,
.chat-contact-list .chat-contacts-container .chat-room-group .chat-profile-info .conversation_count,
.chat-contact-list .chat-contacts-container .chat-contact .chat-profile-info .conversation_count,
.chat-contact-list .chat-contacts-container .new-group .chat-profile-info .conversation_count,
.conversation-list .chat-rooms-group-container .chat-room .chat-profile-info .conversation_count,
.conversation-list .chat-rooms-group-container .chat-room-group .chat-profile-info .conversation_count,
.conversation-list .chat-rooms-group-container .chat-contact .chat-profile-info .conversation_count,
.conversation-list .chat-rooms-group-container .new-group .chat-profile-info .conversation_count,
.conversation-list .chat-rooms-container .chat-room .chat-profile-info .conversation_count,
.conversation-list .chat-rooms-container .chat-room-group .chat-profile-info .conversation_count,
.conversation-list .chat-rooms-container .chat-contact .chat-profile-info .conversation_count,
.conversation-list .chat-rooms-container .new-group .chat-profile-info .conversation_count,
.conversation-list .chat-contacts-container .chat-room .chat-profile-info .conversation_count,
.conversation-list .chat-contacts-container .chat-room-group .chat-profile-info .conversation_count,
.conversation-list .chat-contacts-container .chat-contact .chat-profile-info .conversation_count,
.conversation-list .chat-contacts-container .new-group .chat-profile-info .conversation_count {
  font-size: small;
  font-weight: bold;
  color: gray;
}
.chat-list .chat-rooms-group-container .chat-room .contact-profile-info,
.chat-list .chat-rooms-group-container .chat-room-group .contact-profile-info,
.chat-list .chat-rooms-group-container .chat-contact .contact-profile-info,
.chat-list .chat-rooms-group-container .new-group .contact-profile-info,
.chat-list .chat-rooms-container .chat-room .contact-profile-info,
.chat-list .chat-rooms-container .chat-room-group .contact-profile-info,
.chat-list .chat-rooms-container .chat-contact .contact-profile-info,
.chat-list .chat-rooms-container .new-group .contact-profile-info,
.chat-list .chat-contacts-container .chat-room .contact-profile-info,
.chat-list .chat-contacts-container .chat-room-group .contact-profile-info,
.chat-list .chat-contacts-container .chat-contact .contact-profile-info,
.chat-list .chat-contacts-container .new-group .contact-profile-info,
.chat-contact-list .chat-rooms-group-container .chat-room .contact-profile-info,
.chat-contact-list .chat-rooms-group-container .chat-room-group .contact-profile-info,
.chat-contact-list .chat-rooms-group-container .chat-contact .contact-profile-info,
.chat-contact-list .chat-rooms-group-container .new-group .contact-profile-info,
.chat-contact-list .chat-rooms-container .chat-room .contact-profile-info,
.chat-contact-list .chat-rooms-container .chat-room-group .contact-profile-info,
.chat-contact-list .chat-rooms-container .chat-contact .contact-profile-info,
.chat-contact-list .chat-rooms-container .new-group .contact-profile-info,
.chat-contact-list .chat-contacts-container .chat-room .contact-profile-info,
.chat-contact-list .chat-contacts-container .chat-room-group .contact-profile-info,
.chat-contact-list .chat-contacts-container .chat-contact .contact-profile-info,
.chat-contact-list .chat-contacts-container .new-group .contact-profile-info,
.conversation-list .chat-rooms-group-container .chat-room .contact-profile-info,
.conversation-list .chat-rooms-group-container .chat-room-group .contact-profile-info,
.conversation-list .chat-rooms-group-container .chat-contact .contact-profile-info,
.conversation-list .chat-rooms-group-container .new-group .contact-profile-info,
.conversation-list .chat-rooms-container .chat-room .contact-profile-info,
.conversation-list .chat-rooms-container .chat-room-group .contact-profile-info,
.conversation-list .chat-rooms-container .chat-contact .contact-profile-info,
.conversation-list .chat-rooms-container .new-group .contact-profile-info,
.conversation-list .chat-contacts-container .chat-room .contact-profile-info,
.conversation-list .chat-contacts-container .chat-room-group .contact-profile-info,
.conversation-list .chat-contacts-container .chat-contact .contact-profile-info,
.conversation-list .chat-contacts-container .new-group .contact-profile-info {
  margin-left: var(--margin-md);
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.chat-list .chat-rooms-group-container .chat-room .contact-profile-info .contact-name,
.chat-list .chat-rooms-group-container .chat-room-group .contact-profile-info .contact-name,
.chat-list .chat-rooms-group-container .chat-contact .contact-profile-info .contact-name,
.chat-list .chat-rooms-group-container .new-group .contact-profile-info .contact-name,
.chat-list .chat-rooms-container .chat-room .contact-profile-info .contact-name,
.chat-list .chat-rooms-container .chat-room-group .contact-profile-info .contact-name,
.chat-list .chat-rooms-container .chat-contact .contact-profile-info .contact-name,
.chat-list .chat-rooms-container .new-group .contact-profile-info .contact-name,
.chat-list .chat-contacts-container .chat-room .contact-profile-info .contact-name,
.chat-list .chat-contacts-container .chat-room-group .contact-profile-info .contact-name,
.chat-list .chat-contacts-container .chat-contact .contact-profile-info .contact-name,
.chat-list .chat-contacts-container .new-group .contact-profile-info .contact-name,
.chat-contact-list .chat-rooms-group-container .chat-room .contact-profile-info .contact-name,
.chat-contact-list .chat-rooms-group-container .chat-room-group .contact-profile-info .contact-name,
.chat-contact-list .chat-rooms-group-container .chat-contact .contact-profile-info .contact-name,
.chat-contact-list .chat-rooms-group-container .new-group .contact-profile-info .contact-name,
.chat-contact-list .chat-rooms-container .chat-room .contact-profile-info .contact-name,
.chat-contact-list .chat-rooms-container .chat-room-group .contact-profile-info .contact-name,
.chat-contact-list .chat-rooms-container .chat-contact .contact-profile-info .contact-name,
.chat-contact-list .chat-rooms-container .new-group .contact-profile-info .contact-name,
.chat-contact-list .chat-contacts-container .chat-room .contact-profile-info .contact-name,
.chat-contact-list .chat-contacts-container .chat-room-group .contact-profile-info .contact-name,
.chat-contact-list .chat-contacts-container .chat-contact .contact-profile-info .contact-name,
.chat-contact-list .chat-contacts-container .new-group .contact-profile-info .contact-name,
.conversation-list .chat-rooms-group-container .chat-room .contact-profile-info .contact-name,
.conversation-list .chat-rooms-group-container .chat-room-group .contact-profile-info .contact-name,
.conversation-list .chat-rooms-group-container .chat-contact .contact-profile-info .contact-name,
.conversation-list .chat-rooms-group-container .new-group .contact-profile-info .contact-name,
.conversation-list .chat-rooms-container .chat-room .contact-profile-info .contact-name,
.conversation-list .chat-rooms-container .chat-room-group .contact-profile-info .contact-name,
.conversation-list .chat-rooms-container .chat-contact .contact-profile-info .contact-name,
.conversation-list .chat-rooms-container .new-group .contact-profile-info .contact-name,
.conversation-list .chat-contacts-container .chat-room .contact-profile-info .contact-name,
.conversation-list .chat-contacts-container .chat-room-group .contact-profile-info .contact-name,
.conversation-list .chat-contacts-container .chat-contact .contact-profile-info .contact-name,
.conversation-list .chat-contacts-container .new-group .contact-profile-info .contact-name {
  font-weight: bold;
  display: flex;
  align-items: center;
}
.chat-list .chat-rooms-group-container .chat-room .contact-profile-info .chat-icons,
.chat-list .chat-rooms-group-container .chat-room-group .contact-profile-info .chat-icons,
.chat-list .chat-rooms-group-container .chat-contact .contact-profile-info .chat-icons,
.chat-list .chat-rooms-group-container .new-group .contact-profile-info .chat-icons,
.chat-list .chat-rooms-container .chat-room .contact-profile-info .chat-icons,
.chat-list .chat-rooms-container .chat-room-group .contact-profile-info .chat-icons,
.chat-list .chat-rooms-container .chat-contact .contact-profile-info .chat-icons,
.chat-list .chat-rooms-container .new-group .contact-profile-info .chat-icons,
.chat-list .chat-contacts-container .chat-room .contact-profile-info .chat-icons,
.chat-list .chat-contacts-container .chat-room-group .contact-profile-info .chat-icons,
.chat-list .chat-contacts-container .chat-contact .contact-profile-info .chat-icons,
.chat-list .chat-contacts-container .new-group .contact-profile-info .chat-icons,
.chat-contact-list .chat-rooms-group-container .chat-room .contact-profile-info .chat-icons,
.chat-contact-list .chat-rooms-group-container .chat-room-group .contact-profile-info .chat-icons,
.chat-contact-list .chat-rooms-group-container .chat-contact .contact-profile-info .chat-icons,
.chat-contact-list .chat-rooms-group-container .new-group .contact-profile-info .chat-icons,
.chat-contact-list .chat-rooms-container .chat-room .contact-profile-info .chat-icons,
.chat-contact-list .chat-rooms-container .chat-room-group .contact-profile-info .chat-icons,
.chat-contact-list .chat-rooms-container .chat-contact .contact-profile-info .chat-icons,
.chat-contact-list .chat-rooms-container .new-group .contact-profile-info .chat-icons,
.chat-contact-list .chat-contacts-container .chat-room .contact-profile-info .chat-icons,
.chat-contact-list .chat-contacts-container .chat-room-group .contact-profile-info .chat-icons,
.chat-contact-list .chat-contacts-container .chat-contact .contact-profile-info .chat-icons,
.chat-contact-list .chat-contacts-container .new-group .contact-profile-info .chat-icons,
.conversation-list .chat-rooms-group-container .chat-room .contact-profile-info .chat-icons,
.conversation-list .chat-rooms-group-container .chat-room-group .contact-profile-info .chat-icons,
.conversation-list .chat-rooms-group-container .chat-contact .contact-profile-info .chat-icons,
.conversation-list .chat-rooms-group-container .new-group .contact-profile-info .chat-icons,
.conversation-list .chat-rooms-container .chat-room .contact-profile-info .chat-icons,
.conversation-list .chat-rooms-container .chat-room-group .contact-profile-info .chat-icons,
.conversation-list .chat-rooms-container .chat-contact .contact-profile-info .chat-icons,
.conversation-list .chat-rooms-container .new-group .contact-profile-info .chat-icons,
.conversation-list .chat-contacts-container .chat-room .contact-profile-info .chat-icons,
.conversation-list .chat-contacts-container .chat-room-group .contact-profile-info .chat-icons,
.conversation-list .chat-contacts-container .chat-contact .contact-profile-info .chat-icons,
.conversation-list .chat-contacts-container .new-group .contact-profile-info .chat-icons {
  display: flex;
  padding: 0px 30px;
}
.chat-list .chat-rooms-group-container .chat-room .contact-profile-info .chat-icons .chat-icon,
.chat-list .chat-rooms-group-container .chat-room-group .contact-profile-info .chat-icons .chat-icon,
.chat-list .chat-rooms-group-container .chat-contact .contact-profile-info .chat-icons .chat-icon,
.chat-list .chat-rooms-group-container .new-group .contact-profile-info .chat-icons .chat-icon,
.chat-list .chat-rooms-container .chat-room .contact-profile-info .chat-icons .chat-icon,
.chat-list .chat-rooms-container .chat-room-group .contact-profile-info .chat-icons .chat-icon,
.chat-list .chat-rooms-container .chat-contact .contact-profile-info .chat-icons .chat-icon,
.chat-list .chat-rooms-container .new-group .contact-profile-info .chat-icons .chat-icon,
.chat-list .chat-contacts-container .chat-room .contact-profile-info .chat-icons .chat-icon,
.chat-list .chat-contacts-container .chat-room-group .contact-profile-info .chat-icons .chat-icon,
.chat-list .chat-contacts-container .chat-contact .contact-profile-info .chat-icons .chat-icon,
.chat-list .chat-contacts-container .new-group .contact-profile-info .chat-icons .chat-icon,
.chat-contact-list .chat-rooms-group-container .chat-room .contact-profile-info .chat-icons .chat-icon,
.chat-contact-list .chat-rooms-group-container .chat-room-group .contact-profile-info .chat-icons .chat-icon,
.chat-contact-list .chat-rooms-group-container .chat-contact .contact-profile-info .chat-icons .chat-icon,
.chat-contact-list .chat-rooms-group-container .new-group .contact-profile-info .chat-icons .chat-icon,
.chat-contact-list .chat-rooms-container .chat-room .contact-profile-info .chat-icons .chat-icon,
.chat-contact-list .chat-rooms-container .chat-room-group .contact-profile-info .chat-icons .chat-icon,
.chat-contact-list .chat-rooms-container .chat-contact .contact-profile-info .chat-icons .chat-icon,
.chat-contact-list .chat-rooms-container .new-group .contact-profile-info .chat-icons .chat-icon,
.chat-contact-list .chat-contacts-container .chat-room .contact-profile-info .chat-icons .chat-icon,
.chat-contact-list .chat-contacts-container .chat-room-group .contact-profile-info .chat-icons .chat-icon,
.chat-contact-list .chat-contacts-container .chat-contact .contact-profile-info .chat-icons .chat-icon,
.chat-contact-list .chat-contacts-container .new-group .contact-profile-info .chat-icons .chat-icon,
.conversation-list .chat-rooms-group-container .chat-room .contact-profile-info .chat-icons .chat-icon,
.conversation-list .chat-rooms-group-container .chat-room-group .contact-profile-info .chat-icons .chat-icon,
.conversation-list .chat-rooms-group-container .chat-contact .contact-profile-info .chat-icons .chat-icon,
.conversation-list .chat-rooms-group-container .new-group .contact-profile-info .chat-icons .chat-icon,
.conversation-list .chat-rooms-container .chat-room .contact-profile-info .chat-icons .chat-icon,
.conversation-list .chat-rooms-container .chat-room-group .contact-profile-info .chat-icons .chat-icon,
.conversation-list .chat-rooms-container .chat-contact .contact-profile-info .chat-icons .chat-icon,
.conversation-list .chat-rooms-container .new-group .contact-profile-info .chat-icons .chat-icon,
.conversation-list .chat-contacts-container .chat-room .contact-profile-info .chat-icons .chat-icon,
.conversation-list .chat-contacts-container .chat-room-group .contact-profile-info .chat-icons .chat-icon,
.conversation-list .chat-contacts-container .chat-contact .contact-profile-info .chat-icons .chat-icon,
.conversation-list .chat-contacts-container .new-group .contact-profile-info .chat-icons .chat-icon {
  margin-right: 20px;
}
.chat-list .chat-rooms-group-container .chat-room .contact-profile-info .chat-icons .selected .check-icon,
.chat-list .chat-rooms-group-container .chat-room-group .contact-profile-info .chat-icons .selected .check-icon,
.chat-list .chat-rooms-group-container .chat-contact .contact-profile-info .chat-icons .selected .check-icon,
.chat-list .chat-rooms-group-container .new-group .contact-profile-info .chat-icons .selected .check-icon,
.chat-list .chat-rooms-container .chat-room .contact-profile-info .chat-icons .selected .check-icon,
.chat-list .chat-rooms-container .chat-room-group .contact-profile-info .chat-icons .selected .check-icon,
.chat-list .chat-rooms-container .chat-contact .contact-profile-info .chat-icons .selected .check-icon,
.chat-list .chat-rooms-container .new-group .contact-profile-info .chat-icons .selected .check-icon,
.chat-list .chat-contacts-container .chat-room .contact-profile-info .chat-icons .selected .check-icon,
.chat-list .chat-contacts-container .chat-room-group .contact-profile-info .chat-icons .selected .check-icon,
.chat-list .chat-contacts-container .chat-contact .contact-profile-info .chat-icons .selected .check-icon,
.chat-list .chat-contacts-container .new-group .contact-profile-info .chat-icons .selected .check-icon,
.chat-contact-list .chat-rooms-group-container .chat-room .contact-profile-info .chat-icons .selected .check-icon,
.chat-contact-list .chat-rooms-group-container .chat-room-group .contact-profile-info .chat-icons .selected .check-icon,
.chat-contact-list .chat-rooms-group-container .chat-contact .contact-profile-info .chat-icons .selected .check-icon,
.chat-contact-list .chat-rooms-group-container .new-group .contact-profile-info .chat-icons .selected .check-icon,
.chat-contact-list .chat-rooms-container .chat-room .contact-profile-info .chat-icons .selected .check-icon,
.chat-contact-list .chat-rooms-container .chat-room-group .contact-profile-info .chat-icons .selected .check-icon,
.chat-contact-list .chat-rooms-container .chat-contact .contact-profile-info .chat-icons .selected .check-icon,
.chat-contact-list .chat-rooms-container .new-group .contact-profile-info .chat-icons .selected .check-icon,
.chat-contact-list .chat-contacts-container .chat-room .contact-profile-info .chat-icons .selected .check-icon,
.chat-contact-list .chat-contacts-container .chat-room-group .contact-profile-info .chat-icons .selected .check-icon,
.chat-contact-list .chat-contacts-container .chat-contact .contact-profile-info .chat-icons .selected .check-icon,
.chat-contact-list .chat-contacts-container .new-group .contact-profile-info .chat-icons .selected .check-icon,
.conversation-list .chat-rooms-group-container .chat-room .contact-profile-info .chat-icons .selected .check-icon,
.conversation-list .chat-rooms-group-container .chat-room-group .contact-profile-info .chat-icons .selected .check-icon,
.conversation-list .chat-rooms-group-container .chat-contact .contact-profile-info .chat-icons .selected .check-icon,
.conversation-list .chat-rooms-group-container .new-group .contact-profile-info .chat-icons .selected .check-icon,
.conversation-list .chat-rooms-container .chat-room .contact-profile-info .chat-icons .selected .check-icon,
.conversation-list .chat-rooms-container .chat-room-group .contact-profile-info .chat-icons .selected .check-icon,
.conversation-list .chat-rooms-container .chat-contact .contact-profile-info .chat-icons .selected .check-icon,
.conversation-list .chat-rooms-container .new-group .contact-profile-info .chat-icons .selected .check-icon,
.conversation-list .chat-contacts-container .chat-room .contact-profile-info .chat-icons .selected .check-icon,
.conversation-list .chat-contacts-container .chat-room-group .contact-profile-info .chat-icons .selected .check-icon,
.conversation-list .chat-contacts-container .chat-contact .contact-profile-info .chat-icons .selected .check-icon,
.conversation-list .chat-contacts-container .new-group .contact-profile-info .chat-icons .selected .check-icon {
  position: relative;
}
.chat-list .chat-rooms-group-container .chat-room .chat-date,
.chat-list .chat-rooms-group-container .chat-room-group .chat-date,
.chat-list .chat-rooms-group-container .chat-contact .chat-date,
.chat-list .chat-rooms-group-container .new-group .chat-date,
.chat-list .chat-rooms-container .chat-room .chat-date,
.chat-list .chat-rooms-container .chat-room-group .chat-date,
.chat-list .chat-rooms-container .chat-contact .chat-date,
.chat-list .chat-rooms-container .new-group .chat-date,
.chat-list .chat-contacts-container .chat-room .chat-date,
.chat-list .chat-contacts-container .chat-room-group .chat-date,
.chat-list .chat-contacts-container .chat-contact .chat-date,
.chat-list .chat-contacts-container .new-group .chat-date,
.chat-contact-list .chat-rooms-group-container .chat-room .chat-date,
.chat-contact-list .chat-rooms-group-container .chat-room-group .chat-date,
.chat-contact-list .chat-rooms-group-container .chat-contact .chat-date,
.chat-contact-list .chat-rooms-group-container .new-group .chat-date,
.chat-contact-list .chat-rooms-container .chat-room .chat-date,
.chat-contact-list .chat-rooms-container .chat-room-group .chat-date,
.chat-contact-list .chat-rooms-container .chat-contact .chat-date,
.chat-contact-list .chat-rooms-container .new-group .chat-date,
.chat-contact-list .chat-contacts-container .chat-room .chat-date,
.chat-contact-list .chat-contacts-container .chat-room-group .chat-date,
.chat-contact-list .chat-contacts-container .chat-contact .chat-date,
.chat-contact-list .chat-contacts-container .new-group .chat-date,
.conversation-list .chat-rooms-group-container .chat-room .chat-date,
.conversation-list .chat-rooms-group-container .chat-room-group .chat-date,
.conversation-list .chat-rooms-group-container .chat-contact .chat-date,
.conversation-list .chat-rooms-group-container .new-group .chat-date,
.conversation-list .chat-rooms-container .chat-room .chat-date,
.conversation-list .chat-rooms-container .chat-room-group .chat-date,
.conversation-list .chat-rooms-container .chat-contact .chat-date,
.conversation-list .chat-rooms-container .new-group .chat-date,
.conversation-list .chat-contacts-container .chat-room .chat-date,
.conversation-list .chat-contacts-container .chat-room-group .chat-date,
.conversation-list .chat-contacts-container .chat-contact .chat-date,
.conversation-list .chat-contacts-container .new-group .chat-date {
  color: var(--text-muted);
  font-size: small;
}
.chat-list .chat-rooms-group-container .chat-room .topic-count,
.chat-list .chat-rooms-group-container .chat-room-group .topic-count,
.chat-list .chat-rooms-group-container .chat-contact .topic-count,
.chat-list .chat-rooms-group-container .new-group .topic-count,
.chat-list .chat-rooms-container .chat-room .topic-count,
.chat-list .chat-rooms-container .chat-room-group .topic-count,
.chat-list .chat-rooms-container .chat-contact .topic-count,
.chat-list .chat-rooms-container .new-group .topic-count,
.chat-list .chat-contacts-container .chat-room .topic-count,
.chat-list .chat-contacts-container .chat-room-group .topic-count,
.chat-list .chat-contacts-container .chat-contact .topic-count,
.chat-list .chat-contacts-container .new-group .topic-count,
.chat-contact-list .chat-rooms-group-container .chat-room .topic-count,
.chat-contact-list .chat-rooms-group-container .chat-room-group .topic-count,
.chat-contact-list .chat-rooms-group-container .chat-contact .topic-count,
.chat-contact-list .chat-rooms-group-container .new-group .topic-count,
.chat-contact-list .chat-rooms-container .chat-room .topic-count,
.chat-contact-list .chat-rooms-container .chat-room-group .topic-count,
.chat-contact-list .chat-rooms-container .chat-contact .topic-count,
.chat-contact-list .chat-rooms-container .new-group .topic-count,
.chat-contact-list .chat-contacts-container .chat-room .topic-count,
.chat-contact-list .chat-contacts-container .chat-room-group .topic-count,
.chat-contact-list .chat-contacts-container .chat-contact .topic-count,
.chat-contact-list .chat-contacts-container .new-group .topic-count,
.conversation-list .chat-rooms-group-container .chat-room .topic-count,
.conversation-list .chat-rooms-group-container .chat-room-group .topic-count,
.conversation-list .chat-rooms-group-container .chat-contact .topic-count,
.conversation-list .chat-rooms-group-container .new-group .topic-count,
.conversation-list .chat-rooms-container .chat-room .topic-count,
.conversation-list .chat-rooms-container .chat-room-group .topic-count,
.conversation-list .chat-rooms-container .chat-contact .topic-count,
.conversation-list .chat-rooms-container .new-group .topic-count,
.conversation-list .chat-contacts-container .chat-room .topic-count,
.conversation-list .chat-contacts-container .chat-room-group .topic-count,
.conversation-list .chat-contacts-container .chat-contact .topic-count,
.conversation-list .chat-contacts-container .new-group .topic-count {
  color: gray;
  font-weight: bold;
  font-size: small;
}
.chat-list .chat-rooms-group-container .chat-room .chat-latest,
.chat-list .chat-rooms-group-container .chat-room-group .chat-latest,
.chat-list .chat-rooms-group-container .chat-contact .chat-latest,
.chat-list .chat-rooms-group-container .new-group .chat-latest,
.chat-list .chat-rooms-container .chat-room .chat-latest,
.chat-list .chat-rooms-container .chat-room-group .chat-latest,
.chat-list .chat-rooms-container .chat-contact .chat-latest,
.chat-list .chat-rooms-container .new-group .chat-latest,
.chat-list .chat-contacts-container .chat-room .chat-latest,
.chat-list .chat-contacts-container .chat-room-group .chat-latest,
.chat-list .chat-contacts-container .chat-contact .chat-latest,
.chat-list .chat-contacts-container .new-group .chat-latest,
.chat-contact-list .chat-rooms-group-container .chat-room .chat-latest,
.chat-contact-list .chat-rooms-group-container .chat-room-group .chat-latest,
.chat-contact-list .chat-rooms-group-container .chat-contact .chat-latest,
.chat-contact-list .chat-rooms-group-container .new-group .chat-latest,
.chat-contact-list .chat-rooms-container .chat-room .chat-latest,
.chat-contact-list .chat-rooms-container .chat-room-group .chat-latest,
.chat-contact-list .chat-rooms-container .chat-contact .chat-latest,
.chat-contact-list .chat-rooms-container .new-group .chat-latest,
.chat-contact-list .chat-contacts-container .chat-room .chat-latest,
.chat-contact-list .chat-contacts-container .chat-room-group .chat-latest,
.chat-contact-list .chat-contacts-container .chat-contact .chat-latest,
.chat-contact-list .chat-contacts-container .new-group .chat-latest,
.conversation-list .chat-rooms-group-container .chat-room .chat-latest,
.conversation-list .chat-rooms-group-container .chat-room-group .chat-latest,
.conversation-list .chat-rooms-group-container .chat-contact .chat-latest,
.conversation-list .chat-rooms-group-container .new-group .chat-latest,
.conversation-list .chat-rooms-container .chat-room .chat-latest,
.conversation-list .chat-rooms-container .chat-room-group .chat-latest,
.conversation-list .chat-rooms-container .chat-contact .chat-latest,
.conversation-list .chat-rooms-container .new-group .chat-latest,
.conversation-list .chat-contacts-container .chat-room .chat-latest,
.conversation-list .chat-contacts-container .chat-room-group .chat-latest,
.conversation-list .chat-contacts-container .chat-contact .chat-latest,
.conversation-list .chat-contacts-container .new-group .chat-latest {
  justify-content: center;
  align-items: center;
  font-size: 10px;
  width: 17px;
  height: 17px;
  margin-left: 10px;
  background: var(--red-avatar-color);
  color: white;
  border-radius: 50%;
}
.chat-list .chat-rooms-group-container .loading-more,
.chat-list .chat-rooms-container .loading-more,
.chat-list .chat-contacts-container .loading-more,
.chat-contact-list .chat-rooms-group-container .loading-more,
.chat-contact-list .chat-rooms-container .loading-more,
.chat-contact-list .chat-contacts-container .loading-more,
.conversation-list .chat-rooms-group-container .loading-more,
.conversation-list .chat-rooms-container .loading-more,
.conversation-list .chat-contacts-container .loading-more {
  padding: 10px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

.chatbot-space {
  height: 582px;
  width: 350px;
  position: relative;
  box-shadow: 0px 2px 6px rgba(17, 43, 66, 0.08), 0px 1px 4px rgba(17, 43, 66, 0.1);
  background: var(--card-bg);
  border-radius: 6px;
  margin-bottom: 1rem;
  margin-right: 5px;
  border: 1px solid var(--dark-border-color);
}
.chatbot-space .chat-header {
  cursor: pointer;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}
.chatbot-space .chatbot-container {
  padding: 12px;
  overflow-wrap: break-word;
  height: 450.5px;
  overflow-y: scroll;
}
.chatbot-space .chatbot-container .chat-image {
  border-radius: 13px 13px 0px 13px;
}
.chatbot-space .chatbot-container .arrow-button {
  position: absolute;
  right: 14px;
  bottom: 90px;
  z-index: 1;
  display: none;
  flex-direction: column;
}
.chatbot-space .chatbot-container .arrow-button .arrow {
  background-color: #f4f5f6;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-color: #798287;
  border-style: solid;
}
.chatbot-space .chatbot-container .arrow-button .arrow .btn-ar {
  color: #798287;
}
.chatbot-space .chatbot-container .message-name {
  font-size: 0.75rem;
  font-weight: bold;
}
.chatbot-space .chatbot-container .sender-message {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 5px;
}
.chatbot-space .chatbot-container .sender-message .message-bubble {
  background: var(--gray-200);
  color: #000000;
  padding: 8px;
  font-size: 0.875rem;
  border-radius: 13px 13px 13px 0px;
  max-width: 78%;
}
.chatbot-space .chatbot-container .recipient-message {
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.chatbot-space .chatbot-container .recipient-message .message-bubble {
  background: var(--control-bg);
  color: var(--text-color);
  padding: 8px;
  font-size: 0.875rem;
  border-radius: 13px 13px 0px 13px;
  max-width: 78%;
}
.chatbot-space .chatbot-container .message-time {
  font-size: 0.75rem;
  color: var(--text-muted);
}
.chatbot-space .chatbot-container .date-line {
  width: 100%;
  text-align: center;
  margin: 10px 0 20px;
}
.chatbot-space .chatbot-container .date-line span {
  background: var(--card-bg);
  padding: 0 8px;
  font-size: 0.875rem;
}
.chatbot-space .chatbot-container .date-line .for_line {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
}
.chatbot-space .chatbot-container .date-line .left-line {
  border-bottom: 1px solid var(--gray-400);
  line-height: 0.1em;
  flex-grow: 1;
}
.chatbot-space .chatbot-container .date-line .right-line {
  border-bottom: 1px solid var(--gray-400);
  line-height: 0.1em;
  flex-grow: 1;
}
.chatbot-space .chatbot-container .date-line .between-lines {
  max-width: 100%;
}
.chatbot-space .chat-space-actions {
  padding: 3px 12px 12px 12px;
  height: 75px;
  display: flex;
  align-items: center;
}
.chatbot-space .chat-space-actions .type-message {
  margin: 0 10px;
  font-size: 0.875rem;
  padding: 10px;
}
.chatbot-space .chat-space-actions .message-send-button {
  cursor: pointer;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background: var(--blue-500);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}
.chatbot-space .chat-space-actions .message-send-button svg {
  fill: var(--white);
  width: 14px;
  height: 14px;
}

.chat-topic-space {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.chat-topic-space .chat-header {
  cursor: pointer;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}
.chat-topic-space .chat-topic-space-container {
  padding: 12px;
  overflow-wrap: break-word;
  height: 470.5px;
  overflow-y: scroll;
}
.chat-topic-space .chat-topic-space-container .chat-image {
  border-radius: 13px 13px 0px 13px;
}
.chat-topic-space .chat-topic-space-container .arrow-button {
  position: absolute;
  right: 14px;
  bottom: 90px;
  z-index: 1;
  display: none;
  flex-direction: column;
}
.chat-topic-space .chat-topic-space-container .arrow-button .arrow {
  background-color: #f4f5f6;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-color: #798287;
  border-style: solid;
}
.chat-topic-space .chat-topic-space-container .arrow-button .arrow .btn-ar {
  color: #798287;
}
.chat-topic-space .chat-topic-space-container .message-name {
  font-size: 0.75rem;
  font-weight: bold;
}
.chat-topic-space .chat-topic-space-container .sender-message {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 5px;
}
.chat-topic-space .chat-topic-space-container .sender-message .message-bubble {
  background: var(--gray-200);
  color: #000000;
  padding: 8px;
  font-size: 0.875rem;
  border-radius: 13px 13px 13px 0px;
  max-width: 78%;
}
.chat-topic-space .chat-topic-space-container .recipient-message {
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.chat-topic-space .chat-topic-space-container .recipient-message .message-bubble {
  background: var(--control-bg);
  color: var(--text-color);
  padding: 8px;
  font-size: 0.875rem;
  border-radius: 13px 13px 0px 13px;
  max-width: 78%;
}
.chat-topic-space .chat-topic-space-container .voice-clip-container {
  width: 235px;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  margin-right: 8px;
  margin-left: 4px;
  flex-wrap: wrap;
}
.chat-topic-space .chat-topic-space-container .voice-clip-container .audio-btn {
  color: #505252;
  position: relative;
  width: 34px;
  height: 34px;
  border: none;
}
.chat-topic-space .chat-topic-space-container .voice-clip-container .audio-btn .stop-btn {
  display: none;
}
.chat-topic-space .chat-topic-space-container .voice-clip-container .record-sec {
  position: relative;
  overflow: hidden;
  --progress-width: 0%;
  flex: 1;
  margin-left: 5px;
  background-color: #e2e3e3;
  padding: 5px;
  border-radius: 13px 13px;
  margin-right: 5px;
  /* Start at the edge of the padding */
  /* Adjust for padding */
}
.chat-topic-space .chat-topic-space-container .voice-clip-container .record-sec::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: var(--progress-width);
  background-color: gold;
  transition: none;
}
.chat-topic-space .chat-topic-space-container .voice-clip-container .record-sec .record-line {
  display: flex;
  height: 20px;
}
.chat-topic-space .chat-topic-space-container .voice-clip-container .record-sec .record-line .record-canvas {
  position: relative;
  flex-grow: 1;
  cursor: pointer;
  width: 100px;
  height: 15px;
  margin: 2px auto;
}
.chat-topic-space .chat-topic-space-container .voice-clip-container .duration {
  flex-basis: 100%;
  font-size: 10px;
  line-height: 15px;
  margin: 3px 0px 0px 5px;
}
.chat-topic-space .chat-topic-space-container .message-time {
  font-size: 0.75rem;
  color: var(--text-muted);
}
.chat-topic-space .chat-topic-space-container .date-line {
  width: 100%;
  text-align: center;
  margin: 10px 0 20px;
}
.chat-topic-space .chat-topic-space-container .date-line span {
  background: var(--card-bg);
  padding: 0 8px;
  font-size: 0.875rem;
}
.chat-topic-space .chat-topic-space-container .date-line .for_line {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
}
.chat-topic-space .chat-topic-space-container .date-line .left-line {
  border-bottom: 1px solid var(--gray-400);
  line-height: 0.1em;
  flex-grow: 1;
}
.chat-topic-space .chat-topic-space-container .date-line .right-line {
  border-bottom: 1px solid var(--gray-400);
  line-height: 0.1em;
  flex-grow: 1;
}
.chat-topic-space .chat-topic-space-container .date-line .between-lines {
  max-width: 100%;
}
.chat-topic-space .chat-topic-space-container .mention {
  color: black;
  background-color: #ffffff;
}
.chat-topic-space .chat-topic-space-container .info-message {
  background-color: whitesmoke;
  text-align: center;
  border-radius: 7px;
  padding: 7px;
  margin-bottom: 15px;
}

.chat-space {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.chat-space .chat-header {
  cursor: pointer;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}
.chat-space .mentioned-doctype-section {
  display: flex;
  justify-content: flex-end;
  color: white;
  background-color: #088dff;
  position: sticky;
  width: 348px;
  padding: 5px;
  z-index: 999;
}
.chat-space .mentioned-doctype-section .remove-topic {
  cursor: pointer;
  margin-right: 12px;
}
.chat-space .mentioned-doctype-section .edit-chat-topic-subject {
  cursor: pointer;
}
.chat-space .mentioned-doctype-section .topic-status {
  cursor: pointer;
}
.chat-space .ql-editor {
  padding: 12px 28px 12px 22px !important;
  max-height: 75px;
  min-height: 50px;
  position: absolute;
  bottom: -24px;
  width: 100%;
  color: var(--text-color);
  background-color: var(--control-bg);
  background-clip: padding-box;
}
.chat-space .ql-editor p {
  min-height: 23px;
  -webkit-user-select: text;
     -moz-user-select: text;
          user-select: text;
  white-space: pre-wrap;
  word-break: break-word;
}
.chat-space .ql-mention-list-container {
  font-family: "Inter";
}
.chat-space .ql-mention-list-container .doc-id {
  font-size: 13px;
}
.chat-space .ql-mention-list-container .doc-title {
  font-size: 12px;
  color: gray;
}
.chat-space .ql-mention-list-container .ql-mention-list {
  overflow: auto;
}
.chat-space .tag-section {
  display: flex;
  align-items: center;
  overflow-x: auto;
  overflow-y: auto;
  background-color: white;
}
.chat-space .tag-section .tags-container {
  background-color: #ededed;
  right: 58px;
  width: 230px;
  bottom: 75px;
  z-index: 0;
  position: absolute;
  display: none;
  flex-direction: column;
  max-height: 400px;
  overflow: auto;
}
.chat-space .tag-section .tags-container .tag-blot {
  margin: 5px;
  padding: 5px;
  flex-shrink: 0;
  border-bottom: 1px solid #f4f5f6;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.chat-space .tag-section .tags-container .tag-blot .remove-tag {
  color: white;
  cursor: pointer;
  float: right;
  margin-left: 12px;
}
.chat-space .tag-section .tags-container .tag-blot:last-child {
  border-bottom: none;
}
.chat-space .chat-space-container {
  padding: 12px;
  overflow-wrap: break-word;
  height: 450.5px;
  overflow-y: auto;
}
.chat-space .chat-space-container .chat-image {
  border-radius: 13px 13px 0px 13px;
}
.chat-space .chat-space-container .arrow-button {
  position: absolute;
  right: 14px;
  bottom: 90px;
  z-index: 1;
  display: none;
  flex-direction: column;
}
.chat-space .chat-space-container .arrow-button .arrow {
  background-color: #f4f5f6;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-color: #798287;
  border-style: solid;
}
.chat-space .chat-space-container .arrow-button .arrow .btn-ar {
  color: #798287;
}
.chat-space .chat-space-container .message-name {
  font-size: 0.75rem;
  font-weight: bold;
}
.chat-space .chat-space-container .sender-message {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 5px;
}
.chat-space .chat-space-container .sender-message .message-bubble {
  background: var(--gray-200);
  color: #000000;
  padding: 8px;
  font-size: 0.875rem;
  border-radius: 13px 13px 13px 0px;
  max-width: 78%;
}
.chat-space .chat-space-container .recipient-message {
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.chat-space .chat-space-container .recipient-message .message-bubble {
  background: var(--control-bg);
  color: var(--text-color);
  padding: 8px;
  font-size: 0.875rem;
  border-radius: 13px 13px 0px 13px;
  max-width: 78%;
}
.chat-space .chat-space-container .voice-clip-container {
  width: 235px;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  margin-right: 8px;
  margin-left: 4px;
  flex-wrap: wrap;
}
.chat-space .chat-space-container .voice-clip-container .audio-btn {
  color: #505252;
  position: relative;
  width: 34px;
  height: 34px;
  border: none;
}
.chat-space .chat-space-container .voice-clip-container .audio-btn .stop-btn {
  display: none;
}
.chat-space .chat-space-container .voice-clip-container .record-sec {
  position: relative;
  overflow: hidden;
  --progress-width: 0%;
  flex: 1;
  margin-left: 5px;
  background-color: #e2e3e3;
  padding: 5px;
  border-radius: 13px 13px;
  margin-right: 5px;
  /* Start at the edge of the padding */
  /* Adjust for padding */
}
.chat-space .chat-space-container .voice-clip-container .record-sec::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: var(--progress-width);
  background-color: gold;
  transition: none;
}
.chat-space .chat-space-container .voice-clip-container .record-sec .record-line {
  display: flex;
  height: 20px;
}
.chat-space .chat-space-container .voice-clip-container .record-sec .record-line .record-canvas {
  position: relative;
  flex-grow: 1;
  cursor: pointer;
  width: 100px;
  height: 15px;
  margin: 2px auto;
}
.chat-space .chat-space-container .voice-clip-container .duration {
  flex-basis: 100%;
  font-size: 10px;
  line-height: 15px;
  margin: 3px 0px 0px 5px;
}
.chat-space .chat-space-container .message-time {
  font-size: 0.75rem;
  color: var(--text-muted);
}
.chat-space .chat-space-container .date-line {
  width: 100%;
  text-align: center;
  margin: 10px 0 20px;
}
.chat-space .chat-space-container .date-line span {
  background: var(--card-bg);
  padding: 0 8px;
  font-size: 0.875rem;
}
.chat-space .chat-space-container .date-line .for_line {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
}
.chat-space .chat-space-container .date-line .left-line {
  border-bottom: 1px solid var(--gray-400);
  line-height: 0.1em;
  flex-grow: 1;
}
.chat-space .chat-space-container .date-line .right-line {
  border-bottom: 1px solid var(--gray-400);
  line-height: 0.1em;
  flex-grow: 1;
}
.chat-space .chat-space-container .date-line .between-lines {
  max-width: 100%;
}
.chat-space .chat-space-container .mention {
  color: black;
  background-color: #ffffff;
}
.chat-space .chat-space-container .info-message {
  background-color: whitesmoke;
  text-align: center;
  border-radius: 7px;
  padding: 7px;
  margin-bottom: 15px;
}
.chat-space .chat-space-actions {
  padding: 3px 12px 12px 12px;
  height: 75px;
  display: flex;
  align-items: center;
}
.chat-space .chat-space-actions .open-attach-items {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.chat-space .chat-space-actions .type-message {
  margin: 0 10px;
  font-size: 0.875rem;
  padding: 0px;
}
.chat-space .chat-space-actions .form-control {
  height: unset;
}
.chat-space .chat-space-actions .voice-clip {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.chat-space .chat-space-actions .message-send-button {
  cursor: pointer;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background: var(--blue-500);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}
.chat-space .chat-space-actions .message-send-button svg {
  fill: var(--white);
  width: 14px;
  height: 14px;
}

.delete-sender-name .sender-message .message-bubble .message-name {
  display: none;
}

.chat-form .chat-form-container {
  padding: 12px;
}
.chat-form .chat-form-container .chat-query-heading {
  font-size: 0.875rem;
  color: var(--gray-800);
}
.chat-form .chat-form-container button {
  font-size: 1rem;
}
.chat-form .chat-form-container .form-label {
  font-size: 0.75rem;
  font-weight: bold;
}
.chat-form .chat-form-container #chat-message-area {
  resize: none;
}

.chat-welcome .chat-welcome-header {
  padding: var(--padding-lg);
}
.chat-welcome .chat-welcome-header .hero-icon {
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  background: var(--blue-500);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: var(--margin-md);
}
.chat-welcome .chat-welcome-header .hero-icon svg {
  fill: var(--white);
}
.chat-welcome .chat-welcome-header h3 {
  font-size: 2.25rem;
  font-weight: normal;
  margin-bottom: var(--margin-md);
}
.chat-welcome .chat-welcome-header p {
  font-size: 1.125rem;
}
.chat-welcome .chat-welcome-footer {
  position: absolute;
  padding: 60px var(--padding-md) 0px var(--padding-md);
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--cyan-100);
}
.chat-welcome .chat-welcome-footer .status-content {
  width: 100%;
  margin-bottom: 0;
  font-size: 1.125rem;
  font-weight: bold;
}
.chat-welcome .chat-welcome-footer .hero-content {
  width: 100%;
  font-size: 0.75rem;
}
.chat-welcome .chat-welcome-footer button {
  margin-bottom: var(--margin-md);
  font-size: 1rem;
}
.chat-welcome .chat-welcome-footer .welcome-footer {
  position: initial;
  margin-bottom: var(--margin-sm);
}

.chat-contact-list .chat-contacts-container {
  height: 470.5px;
}

.chat-header {
  display: flex;
  align-items: center;
  padding: 12px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.06), 0px 2px 0.5px -1px rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid var(--dark-border-color);
}
.chat-header .chat-profile-info {
  margin-left: var(--margin-md);
  font-size: 0.875rem;
  margin-right: auto;
  display: flex;
  width: 226px;
  height: 52px;
  flex-direction: column;
  justify-content: center;
}
.chat-header .chat-profile-info .chat-profile-name {
  color: var(--text-color);
  font-weight: 600;
}
.chat-header .chat-profile-info .chat-profile-status {
  font-size: 0.75rem;
}
.chat-header .add-member {
  cursor: pointer;
  margin-right: 11px;
  margin-top: 1px;
}
.chat-header .online-circle {
  display: inline-block;
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 50%;
  margin-left: 3px;
  background: var(--red-avatar-color);
}

.chat-footer {
  width: 100%;
  display: flex;
  position: absolute;
  bottom: 8px;
  justify-content: center;
  font-size: 0.875rem;
  color: var(--gray-600);
}
.chat-footer:hover {
  text-decoration: none;
}

.empty-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.close-chat-window {
  cursor: pointer;
  margin-right: 0px;
  margin-top: 1px;
}

.bg-message {
  background-color: rgba(0, 0, 100, 0.1);
}

.voice-message {
  padding: 3px 12px 12px 12px;
  height: 75px;
  display: flex;
  align-items: center;
}
.voice-message .send-btn svg {
  margin-top: 11px;
}

.voice-section {
  display: none;
}

.message-section {
  display: flex;
  align-items: center;
  width: 100%;
}

.tag-blot-hidden {
  display: none;
}

.tag-blot-hidden:last-child {
  display: block;
}

.contributors_count,
.contributors_close {
  cursor: pointer;
}

.chat-space-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-messages-info {
  text-align: center;
}

.request-access-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (max-width: 576px) {
  .chat-app {
    max-width: 100%;
    bottom: 1rem;
  }
  .chat-bubble-closed {
    display: none;
  }
  .chat-cross-button {
    display: block;
  }
  .chat-list .chat-list-header .chat-list-icons {
    margin-right: var(--margin-xl);
  }
}
.chat-navbar-icon {
  cursor: pointer;
}
.chat-navbar-icon .badge {
  background: var(--red-avatar-color);
  color: var(--bg-color);
  position: relative;
  top: -10px;
  left: -10px;
  border-radius: 50%;
  font-size: 9px;
  border: 1px solid var(--red-avatar-color);
}